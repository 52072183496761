// --- Import Testing Revision
import {CalcFullSize, set_full_size} from './utils/calcFullSize.js';
import scrollTo from './utils/scrollToPlugin';
import { scrollLock, scrollLockSwitch } from './utils/scrollLock.js';
import ContentSwitcher from './utils/ContentSwitcher.js';
import {events} from './utils/events.js';

// --- NAVIGATION
import {setupNav, setupNavTwo} from './utils/setupNav';
import StickyNav from './utils/StickyNav';

// --- Pages
import {fillContH} from './utils/helpers'
// window.testimonialSlider, introSlider;

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

export { ContentSwitcher, scrollLockSwitch, StickyNav, fillContH, CalcFullSize, set_full_size };

// --- Starting Document ready
document.addEventListener('DOMContentLoaded', function () {

  const app = new App();
  app.init();
  // console.log('INIT');

});

class App {

  beforeInit() {
    // setupNav();
    setupNavTwo()
    scrollLock();
    events.init();
  }

  init() {
    // --- Before
    this.beforeInit();
    this.afterInit();
    // console.log("init")
  }

  afterInit() {}
}