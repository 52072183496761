
// http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame    ||
            function( callback ){
              window.setTimeout(callback, 1000 / 60);
            };
  })();
  


// main function
export default function scrollTo(scrollTargetY, speed, easing) {
    // scrollTargetY: the target scrollY property of the window
    // speed: time in pixels per second
    // easing: easing equation to use

    var scrollY = window.scrollY || document.documentElement.scrollTop,
        scrollTargetY = scrollTargetY || 0,
        speed = speed || 4000,
        easing = easing || 'easeOutSine',
        currentTime = 0;

    var isHeaderSticky = false;


    // ---- MODIFICATION => if the sticky navigation is on
    if(document.querySelector('#masthead').classList.contains('header-sticky')) {
        scrollTargetY = scrollTargetY - parseFloat(getComputedStyle(document.querySelector('#masthead'), null).height.replace("px", ""))
        // console.log('Is sticky lolsa', )
    }
    // ---- MODIFICATION => if the sticky navigation is on

    // min time .1, max time .8 seconds
    var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    // console.log('Duration of anim:', time);

    // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
    var easingEquations = {
            easeOutSine: function (pos) {
                return Math.sin(pos * (Math.PI / 2));
            },
            easeInOutSine: function (pos) {
                return (-0.5 * (Math.cos(Math.PI * pos) - 1));
            },
            easeInOutQuint: function (pos) {
                if ((pos /= 0.5) < 1) {
                    return 0.5 * Math.pow(pos, 5);
                }
                return 0.5 * (Math.pow((pos - 2), 5) + 2);
            }
        };

    // add animation loop
    function tick() {

        currentTime =  currentTime + 1 / 60;

        var p = currentTime / time;
        var t = easingEquations[easing](p);

        // console.log('-----------------------');
        // console.log('Current Time: ', currentTime);
        // console.log('-----------------------')

        if (p < 1) {
            // console.log('scrolling here')
            requestAnimFrame(tick);

            window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else {
            // console.log('scroll done');
            window.scrollTo(0, scrollTargetY);
        }
    }

    // call it once to get started
    tick();
}

// -------------------------------------------------
// https://stackoverflow.com/questions/8917921/cross-browser-javascript-not-jquery-scroll-to-top-animation
// export default function scrollTo(element, to, duration) {
//     console.log('being run')
//     if (duration <= 0) return;
//     var difference = to - element.scrollTop;
//     var perTick = difference / duration * 10;

//     setTimeout(function() {
//         element.scrollTop = element.scrollTop + perTick;
//         if (element.scrollTop === to) return;
//         scrollTo(element, to, duration - 10);
//     }, 10);
// }


// -------------------------------------------------

// export default function scrollTo(element, to, duration) {
//     var start = element.scrollTop,
//         change = to - start,
//         increment = 20;

//     var animateScroll = function(elapsedTime) {        
//         elapsedTime += increment;
//         var position = easeInOut(elapsedTime, start, change, duration);                        
//         element.scrollTop = position; 
//         if (elapsedTime < duration) {
//             setTimeout(function() {
//                 animateScroll(elapsedTime);
//             }, increment);
//         }
//     };

//     animateScroll(0);
// }

// function easeInOut(currentTime, start, change, duration) {
//     currentTime /= duration / 2;
//     if (currentTime < 1) {
//         return change / 2 * currentTime * currentTime + start;
//     }
//     currentTime -= 1;
//     return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
// }

// -------------------------------------------------

