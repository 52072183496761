export class CalcFullSize {
    constructor(navbar){
        // --- Calculate the height of browser - Navigation height (When sticky)
        
        // console.log(window.outerHeight)
        this.header = document.querySelector(navbar);
        
        this.init = this.init.bind(this);
        window.addEventListener('resize',this.init);
        this.init();
    }


    init() {
        var $this = this;

        
        this.sectionName = 'section.full-size';
        this.outerHeight = window.innerHeight;


        console.log('Resizing');
        document.querySelectorAll(this.sectionName).forEach(function(v,i){
            // window.h = $this.header;
            if($this.header.classList.contains('header-sticky')) { // .classList.contains('header-sticky')
                // console.log('Does have sticky class');
                // console.log('Full size is: ', ($this.outerHeight - $this.header.offsetHeight))
                v.style.height = ($this.outerHeight - $this.header.offsetHeight) + 'px';
            } else {
                // console.log('Doesnt have sticky class');
                // console.log('Full size is:', window.innerHeight,)
                v.style.height = window.innerHeight + 'px';
            }

            
        })
    }
}

export function set_full_size(el, offset=null){
    // console.log(offset)
    el.style.height = (document.body.offsetHeight - offset)+"px"

}