export function addListenerWithArgs(elem, evt, func, vars) {
  var f = function (ff, vv) {
    return (function () {
      ff(vv);
    });
  }(func, vars);
  elem.addEventListener(evt, f);
  return f;
}

export function $$(selector, context) {
	context = context || document;
	var elements = context.querySelectorAll(selector);
	return Array.prototype.slice.call(elements);
}

export function siblings (el) {
  if (el.parentNode === null) return [];

  return Array.prototype.filter.call(el.parentNode.children, function (child) {
    return child !== el;
  });
}

export function toggle(el, displayoveride='block') {
	if(el.style.display == displayoveride){
		el.style.display = 'none'
	} else {
		el.style.display = displayoveride
	}
}

export function hide(el){
	el.style.display = 'none'
}


export function fillContH(el, resize=false){
  console.log('fill cont')
  let top=el.offsetTop
  let winH=window.innerHeight
  el.style.height=winH-top;

  if (resize){
    el.addEventListener("resize", ()=>{
      top=el.offsetTop
      winH=window.innerHeight
      el.style.height=winH-top;
    })
  }
}