import {addListenerWithArgs} from './helpers';

// https://stackoverflow.com/questions/31282318/is-there-a-way-to-cancel-requestanimationframe-without-a-global-variable


class Animation {
  constructor(/*speed, step*/){
    this.step = this.run;
    this.timerID;
    this.canceled = false;
    this.arr = []
  }
  innerStep(timestamp) {
    
    this.timestamp = timestamp
    this.run()
    // this.startStamp = new Date().getTime()
    if (this.canceled == false) {
      // this.canceled = true
      // console.log("This:::", this)
      // console.log(timestamp, this)

      this.timerID = requestAnimationFrame(this.innerStep.bind(this));
      if(!this.arr.includes(this.timerID)){
        this.arr.push(this.timerID)
        // console.log()
      }
      // this.timerID = requestAnimationFrame(() => {
      //   // timestamp here
      //   // how fast did we get back here
      //   if (this.startStamp - timestamp < this.speed) this.next()

      // });
    } else {
      // this.cancel()
      // this.canceled = false;
      // console.log(this.arr.length)
    }
  }
  run(timestamp){
    // Code runs here
    return "parent"
    // console.log(timestamp)
  }
  start() {
    console.log("started")
    this.timerID = window.requestAnimationFrame(this.innerStep.bind(this));
  }
  cancel() {
    console.log("canceled")
    this.canceled = true;
    window.cancelAnimationFrame(this.timerID)
    return;
  }
}


class Timer extends Animation {
  constructor(/*speed, step*/timerInS, options){
    super()
    this.timerInS = timerInS
    this.step = this.run;
    this.estimatedEnd = Date.now() + this.timerInS *1000,
    this.currentCount = (this.estimatedEnd - Date.now()) / 1000;
    this.lastSecond = timerInS+1
    this.paused = false
    // === OPTIONS
    this.onComplete = options.onComplete || false;
    // console.table("hey")
    // console.log(this)
    // this.timerID;
    // this.canceled = false;
  }
  run(timestamp){
    super.run()
    // console.log(this.currentCount)
    this.currentCount = (this.estimatedEnd - Date.now()) / 1000;
    if(!this.paused){
      this.secondWatcher()
      if(this.currentCount <= 0){
        // console.log("finished")
        // this.canceled = true
        // this.cancel()
        this.canceled = true
        window.cancelAnimationFrame(this.timerID)
        this.estimatedEnd = Date.now() + this.timerInS*1000;
        this.onComplete ? this.onComplete() : false;
        // this.canceled = true
      }
    }
  }
  secondWatcher(){
    this.currentSecond = Math.abs(Math.ceil(this.currentCount))
    // MONITOR LAST SECOND AND CURRENT
    console.log(this.currentSecond, this.lastSecond)
    if(this.currentSecond < this.lastSecond){
      if(this.currentSecond < 0){
        this.lastSecond = this.timerInS+1
      } else {
        this.lastSecond = this.currentSecond
        // console.log(this.currentSecond)
      }
    } else if(this.lastSecond == 0){
      this.lastSecond = this.timerInS+1
    }

  }
  resetTimer(){
    this.estimatedEnd = Date.now() + this.timerInS*1000;
  }
  cancel(){
    super.cancel()
  }
  restart(){
    // window.cancelAnimationFrame(this.timerID)
    this.cancel()
    console.log(this)
    // console.log(this.timerID)
    // this.canceled = true
    this.lastSecond = this.timerInS+1
    // this.timerID = null
    // this.resetTimer()
    this.start()
  }
  pause(){
    this.paused = true
  }
  resume(){
    this.estimatedEnd = Date.now() + this.currentSecond*1000,
    this.paused = false
  }
  start(){
    this.canceled = false
    this.estimatedEnd = Date.now() + this.timerInS *1000,
    super.start()
  }
}


class FadeAnimation extends Animation {
  constructor(
    step=FadeAnimation.step,
    previousElement,
    nextElement,
    speed=1000,
    blur,
    fadeToBlack=true
  ) {
    super(step)
  }

  next() {
    // how to fade an element
  }

  step(){
    console.log("steps")
  }
}
class Slide extends Animation {
  constructor({
    speed,
  }) { }
}

// Handle page visibility change events

// new Timer(10).start()


export default  class ContentSwitcher {
  constructor(options) {
    var $this = this;
    // Options:
    // container -> str (UNIQUE),
    // btn -> bool
    // btnType -> str (arrows, tabs) 
    // animate -> bool
    // animateType -> str (fade, slideIn)
    // display -> str (flex, block...ect)
    this.animate = options.animate || false;
    this.animateType = options.animateType || 'fade';
    this.animateComplete = options.animate ? false : true || true;
    // Looping next button pushed
    // https://stackoverflow.com/a/16763553
    this.loop = options.loop || false;
    this.loopTimerCount = options.loopTimerCount || 10;
    this.loopTimer = new Timer(10, {onComplete: ()=>{
      this.next()
    }});
    this.hasVideo = options.video || false;
    this.videos = [];
    // 
    this.currentIndex = 0;
    this.previousIndex = null;
    this.nextIndex = null;
    this.btn = options.btn || null;
    this.btnType = options.btnType || null;
    this.display = options.display || null;
    this.containerIdentifier = options.container;
    this.elem_cont = document.querySelector(options.container);
    this.children = [].slice.call(this.elem_cont.children);
    this.childrenLen = this.children.length - 1;
    // PAUSE LOOP ON VIS CHANGE
    document.addEventListener("visibilitychange", ()=>{
      if (document.visibilityState == "hidden"){
        this.loopTimer.pause()
      } else if(document.visibilityState == "visible") {
        this.loopTimer.resume()
      }
    });
    window.timer = this.loopTimer
    this.init();
  }

  resetLoopTimer(){
    this.loopTimer.restart()
  }


  init() {
    this.hideExcIndex();

    // console.log(this.btn);
    if(this.btn) {
      this.createBtns();
    }
    if(this.hasVideo){
      this.lookupVideos();
    }
    if(this.loop) {
      this.loopTimer.restart();
    }
  }

  hideExcIndex() {
    // console.log(this.children);
    for (const key in this.children) {
      if(key != 0) {
        // console.log('KEY IS ', Number(key), this.children[key]);
        this.children[key].style.display = 'none';
      }
    }
  }

  next() {
    // this.previousIndex = this.state.slideIndex;
    // console.log(this.currentIndex);
    // this.children.
    if (this.currentIndex === this.children.length - 1) {
      this.nextIndex = 0;

      // this.children[0].style.display = 'none';
      // this.children[].style.display = 'block';
      // this.children[this.children.length - 1].style.display = 'block';
    }
    else {
      this.nextIndex = this.currentIndex + 1;
    }
    // this.timeOut();
    this.handleNext();
    // opt.this.shiftIndex();
    // this.shiftIndex();
  }

  prev() {
    // this.previousIndex = this.state.slideIndex;
    if (this.currentIndex === 0) {
      this.nextIndex = this.children.length - 1;

      // this.children[0].style.display = 'none';
      // this.children[].style.display = 'block';
      // this.children[this.children.length - 1].style.display = 'block';
    }
    else {
      this.nextIndex = this.currentIndex - 1;
    }
    // this.timeOut();
    this.handleNext();
    // this.shiftIndex();
  }

  handleNext(){
    // - MAKE ANIMATION FRAME LOOP 
    // - WHEN FINISHED SHIFT INDEX
    // console.log('handleNext');
    this.handleVideos();
    this.resetLoopTimer();
    this.tabChange();
    // this.animateSlide();
    this.handleLoop();
    // After shiftIndex()

  }

  tabChange(){
    if(this.btnType == 'tab'){
      this.tabs[this.currentIndex].classList.remove('active');
      this.tabs[this.nextIndex].classList.add('active');
    }
  }

  handleLoop(){
    var $this = this;
    if(this.animate == false){
      this.shiftIndex();
      this.handleDisplay() 
    } else {
      if(this.animateType == "fade"){
        // this.animation = new FadeAnimation()
        // this.animation.onComplete(()=>{
        //   console.log(this)
        // })
      }
      // var animation = new Animation(function(){
      //   if($this.animationComplete) {
      //     // console.log('animation complete');
      //     $this.animationComplete = false;
      //     animation.cancel();
      //     $this.shiftIndex();
      //   }
      // });
      // animation.start();
    }
  }

  handleDisplay(){
    if(this.display == 'flex') {
      this.children[this.previousIndex].style.display = 'none';
      // console.log(this.nextIndex);
      this.children[this.currentIndex].style.display = 'flex';
    } else {
      this.children[this.previousIndex].style.display = 'none';
      this.children[this.nextIndex].style.display = 'block';
    }
  }

  shiftIndex() {
    // this.animateSlide();
    // var $this = this;
    // Depending on animations required we will input them here
    this.previousIndex = this.currentIndex;
    this.currentIndex = this.nextIndex;
    // console.log(this.currentIndex);
  }

  createBtns() {
    if(this.btnType == 'tab') {

      var tabContainer = document.createElement('div');
      tabContainer.setAttribute('class', 'tab-container')
      this.tabs = [];

      for(var key = 0; key <= this.childrenLen; key++) {
        this.tabs[key] = document.createElement('div');
        this.tabs[key].setAttribute('class', ('tab-btn ' + 'tab-' + key));
        this.tabs[key].setAttribute('data-id', key);

        if(key == 0) {
          this.tabs[key].classList.add('active');
        }

        tabContainer.appendChild(this.tabs[key]);

        addListenerWithArgs(this.tabs[key], 'click', this.goToSlide, { no: key, this: this })
      }

      this.elem_cont.parentElement.appendChild(tabContainer);

    }

  }

  goToSlide(opt) {
    console.log(opt)
    if(opt.no != opt.this.currentIndex) {
      opt.this.nextIndex = opt.no;
      opt.this.handleNext();
      opt.this.shiftIndex();
    }

  }

  // animateSlide(){
  //   // console.log('animateSlide');
  //   var requestId;
  //   if(this.animate){
  //     switch (this.animateType) {
  //       case 'fade':
  //         this.fade();
  //         break;
      
  //       default:
  //         break;
  //     }
  //   }
  // }

  fade(){
    this.children[this.currentIndex].style.opacity = "1";
    this.children[this.nextIndex].style.opacity = "0"
    this.children[this.nextIndex].style.zIndex = "2"
    this.children[this.currentIndex].style.zIndex = "1"
    var $this = this;
    var animation1Started = false
    var animation1 = new Animation(function(){
      // console.log(this);
      if(animation1Started == false) {

        $this.children[$this.nextIndex].style.display = $this.display;
        animation1Started = true;
      }
      if(Number($this.children[$this.nextIndex].style.opacity) < 1){
        $this.children[$this.nextIndex].style.opacity = (Number($this.children[$this.nextIndex].style.opacity) + 0.05);
      } else {
        animation1.cancel();
        $this.animationComplete = true;
      }
    });
    var animation = new Animation(function(){
      if(Number($this.children[$this.currentIndex].style.opacity) > 0){
        // console.log($this.children[$this.currentIndex].style.opacity);
        if(Number($this.children[$this.currentIndex].style.opacity) < 0.9 && animation1Started == false){
          // console.log('once');
          animation1.start();
        }
        $this.children[$this.currentIndex].style.opacity = (Number($this.children[$this.currentIndex].style.opacity) - 0.05);
      } else {
        animation.cancel();
      }
    });
    animation.start(); 
  }

  handleVideos(){
    if(this.hasVideo){
      if(this.videos[this.currentIndex] != false){
        this.videos[this.currentIndex].pause();
        this.videos[this.currentIndex].currentTime = 0;
      }
      if(this.videos[this.nextIndex] != false){
        // console.log('Handle video ', this.videos[this.nextIndex], true)
        // this.videos[this.nextIndex].currentTime = 0;
        this.videos[this.nextIndex].load();
        this.videos[this.nextIndex].play();
      }
    }
  }

  lookupVideos(){
    console.log('LOOKUP VIDEO');
    // var videos = [];
    for(var key = 0; key <= this.childrenLen; key++) {
      // console.log(key); 0 1 2
      this.videos.push(this.children[key].querySelector('video') || false);
      console.log(this.videos);
    }
  }
} 

