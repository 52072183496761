import { collapseSection, expandSection } from './collapseMenu';
import {scrollLockSwitch} from './scrollLock';
import {$$, siblings, toggle, hide} from './helpers'

export function setupNav(){
  console.log("setup");
  console.log($);
    (function ($) { // Begin jQuery
      $(function () { // DOM ready
        // If a link has a dropdown, add sub menu toggle.
        $('header nav ul li a:not(:only-child)').click(function (e) {
          $(this).siblings('.nav-dropdown').toggle();
          // Close one dropdown when selecting another
          $('.nav-dropdown').not($(this).siblings()).hide();
          e.stopPropagation();
        });
        // Clicking away from dropdown will remove the dropdown class
        $('html').click(function () {
          $('.nav-dropdown').hide();
        });
        // Toggle open and close nav styles on click
        $('#nav-toggle').click(function () {
        });
        // Hamburger to X toggle
        $('#nav-toggle').on('click', function () {
          // console.log("clicked");
          var mainNav = $('header nav ul')[0];

          console.log(mainNav);
          // console.log()
          mainNav.classList.toggle("open");
          this.classList.toggle('clicked');

          var isCollapsed = mainNav.getAttribute('data-collapsed');

          console.log("Header: ", $('header#mainNav')[0])
          if($('header#mainNav')[0]){
            if ($('header#mainNav nav ul')[0].classList.contains("open")) {
              scrollLockSwitch(true);
            } else {
              scrollLockSwitch(false);
            }
          } else if($('header#secondaryNav nav ul')[0]){
            if ($('header#secondaryNav nav ul')[0].classList.contains("open")) {
              expandSection(mainNav);
            } else {
              collapseSection(mainNav);
            }
          }
        });
      }); // end DOM ready
    })(jQuery); // end jQuery
}

export function setupNavTwo(){

  let burger = document.querySelector('#nav-toggle')
  let sib = null;

  document.querySelector('html').addEventListener('click', (el)=>{
    // Siblings
    var sibling = siblings(el.srcElement)[0]
    // console.log(sibling)
    console.log(sib)

    if(sib == null){
      document.querySelectorAll('.nav-dropdown').forEach((v,i)=>{
        console.log(v)
        if(sib != v){
          hide(v)
        }
      })
    }
    sib = null;
  })

  // DROPDOWN MENU
  document.querySelectorAll('header nav ul li a:not(:only-child)').forEach((v,i)=>{
    let dropdowns = siblings(v)[0]
    v.addEventListener('click',(event)=>{
      sib = v.parentElement.querySelector('ul');
      toggle(dropdowns)
    })
  })

  // NAVTOGGLE CLICk
  burger.addEventListener('click', (event)=>{
    // console.log('clicking')
    let mainNav = $$('header nav ul')[0];
    // let burger = event.target
    // console.log(mainNav, this)
    mainNav.classList.toggle('open')
    burger.classList.toggle('clicked')

    if(mainNav.classList.contains('open')){
      expandSection(mainNav)
      
    } else {
      collapseSection(mainNav)
    }

  })

}

// export default function setupNav(){
//   console.log("setup");
//   console.log($);
//     (function ($) { // Begin jQuery
//       $(function () { // DOM ready
//         // If a link has a dropdown, add sub menu toggle.
//         $('header nav ul li a:not(:only-child)').click(function (e) {
//           $(this).siblings('.nav-dropdown').toggle();
//           // Close one dropdown when selecting another
//           $('.nav-dropdown').not($(this).siblings()).hide();
//           e.stopPropagation();
//         });
//         // Clicking away from dropdown will remove the dropdown class
//         $('html').click(function () {
//           $('.nav-dropdown').hide();
//         });
//         // Toggle open and close nav styles on click
//         $('#nav-toggle').click(function () {
//         });
//         // Hamburger to X toggle
//         $('#nav-toggle').on('click', function () {
//           // console.log("clicked");
//           var mainNav = $('header nav ul')[0];
//           // console.log()
//           mainNav.classList.toggle("open");
//           this.classList.toggle('clicked');

//           var isCollapsed = mainNav.getAttribute('data-collapsed');
//           // console.log(isCollapsed);
//           if ($('header nav ul')[0].classList.contains("open")) {
//             expandSection(mainNav);
//           } else {
//             collapseSection(mainNav);
//           }
//         });
//       }); // end DOM ready
//     })(jQuery); // end jQuery
// }

// function collapseSection(element) {
//   var sectionHeight = element.scrollHeight;
//   var elementTransition = element.style.transition;
//   element.style.transition = '';
//   console.log(elementTransition);
//   requestAnimationFrame(function () {
//     element.style.height = sectionHeight + 'px';
//     element.style.transition = elementTransition;
//     requestAnimationFrame(function () {
//       element.style.height = 0 + 'px';
//     });
//   });
//   element.setAttribute('data-collapsed', 'true');
// }

// function expandSection(element) {
//   var sectionHeight = element.scrollHeight;
//   element.style.height = sectionHeight + 'px';
//   element.addEventListener('transitionend', function (e) {
//     element.removeEventListener('transitionend', arguments.call);
//     element.style.height = 'auto';
//   });
//   element.setAttribute('data-collapsed', 'false');
// }

