// export function collapseSection(element) {
//   var sectionHeight = element.scrollHeight;
//   var elementTransition = element.style.transition;
//   element.style.transition = '';
//   console.log(elementTransition);
//   requestAnimationFrame(function () {
//     console.log('request anim')
//     element.style.height = sectionHeight + 'px';
//     element.style.transition = elementTransition;
//     element.addEventListener('transitionend', function (e) {
//       element.removeEventListener('transitionend', arguments.call);
//       element.style.height = 0 + 'px';
//     });
//     // requestAnimationFrame(function () {
//     //   element.style.height = 0 + 'px';
//     // });
//   });
//   element.setAttribute('data-collapsed', 'true');
// }


export function collapseSection(element, oncomplete) {
  var sectionHeight = element.scrollHeight;

    element.style.height=null
    element.style.maxHeight = sectionHeight + 'px';
    
    let handle_trans = function(event){
      console.log("collapse")
      event.target.style.maxHeight="0px"
      element.removeEventListener('transitionend', handle_trans);
    }

    element.style.transition = '0.25s';
  
    window.requestAnimationFrame(()=>{
      element.style.maxHeight = '0px'
    })

    element.addEventListener('transitionend',handle_trans)

    // element.addEventListener('transitionend', function (e) {
    //   console.log('transitionend123')
    //   element.style.maxHeight="0px"
    //   element.removeEventListener('transitionend', arguments.call);
    // });


  element.setAttribute('data-collapsed', 'true');
}



export function expandSection(element) {

  var sectionHeight = element.scrollHeight;
  element.style.maxHeight = sectionHeight + 'px';
  
  let handle_trans = function(event){
    console.log("expand")
    event.target.style.maxHeight = null
    event.target.style.height = 'auto'
    element.removeEventListener('transitionend', handle_trans);
  }

  element.addEventListener('transitionend', handle_trans)

  // element.addEventListener('transitionend', function (e) {
  //   console.log('height auto')
  //   element.style.maxHeight = null
  //   // element.style.maxHeight = null;
  //   element.style.height = 'auto'
  //   element.removeEventListener('transitionend', arguments.call);


  // });
  element.setAttribute('data-collapsed', 'false');
  
}

