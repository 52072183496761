export function scrollLock () {
  console.log('test');
  /*
    Set window.scrollLock to true when 
    you want the window to stop scrolling
  */
  window.scrollLock = false;
  window.previousScrollTop = 0
  var $window = window;
  
  $window.addEventListener('scroll',function (event) {

    // window.previousScrollTop = window.scrollTop;

    // console.log('scrtoll', window.scrollLock)

    if (window.scrollLock) {
      // document.body.style.overflowY = 'hidden';
      
      // console.log(document.body.scrollTop, "dasdad")
      document.body.scrollTop = window.previousScrollTop;
      // $window.scrollTop(window.previousScrollTop);

      // window.scrollTo(0,0)
      // document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      // document.body.style.overflowY = 'unset';
    }

    // window.previousScrollTop = $window.scrollTop();
    window.previousScrollTop = document.body.scrollTop;
  });
}

export function scrollLockSwitch(bool) {
  if (bool) {
    document.body.style.overflowY = 'hidden';
    window.scrollLock = true;
  } else {
    document.body.style.overflowY = 'unset';
    window.scrollLock = false;
  }
}