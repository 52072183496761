export default class StickyNav {
    
    constructor(navid, maincontent){

        this.isSticky = false;
        
        // --- NavigationID
        this.navigationBarID = navid; 
        this.navigationBar = document.querySelector(this.navigationBarID) || null;

        if(this.navigationBar != null) {
            this.navOffsetTop = this.navigationBar.offsetTop;
            this.navHeight = this.navigationBar.offsetHeight;
    
            // --- Main Content ID or (Main)
            this.main = maincontent;
    
            // console.log(navid, this.navigationBar.offsetTop);
            this.init = this.init.bind(this);
            this.init();
        } else {
            console.log('Navigation bar is Null');
        }


    }

    init() {
        window.onscroll = function (e){
            e.preventDefault();
            console.log(this.navOffsetTop);
            if(window.pageYOffset >= (this.navOffsetTop)) {
                this.scrollIsAtTop();
            } else {
                this.scrollBellow();
            }
        }.bind(this);

        window.addEventListener("DOMContentLoaded", function(){
            // console.log('Content Loaded');
            if(window.pageYOffset >= (this.navOffsetTop)) {
                this.scrollIsAtTop();
            } else {
                this.scrollBellow();
            }
        }.bind(this))
    }

    // --- Adds class sticky if above 0 pagetop
    scrollIsAtTop() {
        // document.body.style.paddingTop = document.querySelector(this.navigationBarID).offsetHeight + 'px';
        this.navigationBar.classList.add('sticky');
        document.querySelector(this.main).style.paddingTop = document.querySelector(this.navigationBarID).offsetHeight + 'px';
        
    }

    // --- Applies height of navigation ontop of (Main) content to stop jumping
    scrollBellow() {
        // document.body.style.paddingTop = 0 + 'px';
        this.navigationBar.classList.toggle('sticky', false);
        document.querySelector(this.main).style.paddingTop = 0 + 'px';
    }

}